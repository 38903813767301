/**
 * steps are
 * getloginToken
 * get
 * @type {{getReport(*): Promise<undefined | void>}}
 */
import XLSX from 'xlsx'
import {loginWithToken, getDownloadUrl} from '@/plugins/firebase'
import { deleteDatabase } from '@/api/PouchDao'
import store from "@/store"
const firebaseURL = process.env.VUE_APP_FIREBASE_BASE_URL
const requestLoginToken = firebaseURL + '/requestLoginToken'
const requestIsExpiredLink = firebaseURL + '/requestIsExpiredLink'

const getLoginToken = async (accessKey) => {
    console.log('accessKey is ', accessKey)
    // http://localhost:5001/bv-partner-portal/australia-southeast1/requestLoginToken
    const urlencoded = new URLSearchParams();
    urlencoded.append("accessKey", accessKey);
    return fetch(requestLoginToken, {
        // mode: "cors",
        // mode: 'same-origin', // no-cors, *cors, same-origin
        // headers: {
        //     'Accept': 'application/json',
        //     'Content-Type': 'application/json'
        // },
        method: 'POST',
        body: urlencoded
    }).then(function(response) {
        console.log('response status ' + response.status)
        if (!response.ok) {
            if (response.status == 410){
                console.log('Link Expired')
                deleteDatabase()
            }
            const error = response.statusText
            return Promise.reject(error)
        }
        console.log('success returning json')
        return response.json();
        //return response.text()
    }).then(function(data) {
        console.log('data is ', data)
        return data
    }).catch((error) => {
        console.error("There was an error!", error)
        throw error
    })
}

const checkLink = async (accessKey) => {
    console.log('accessKey is ', accessKey)
    const urlencoded = new URLSearchParams();
    urlencoded.append("accessKey", accessKey);
    return fetch(requestIsExpiredLink, {
        method: 'POST',
        body: urlencoded
    }).then(function(response) {
        return response.json()
    }).catch((error) => {
        console.error("There was an error!", error)
        throw error
    })
}

/**
 * we added a cors.json file to gs://bv-customer-file bucket to get around the CORS issue. refer to README.md to see how to add cors.json to the bucket using gsutil
 * @param downloadUrl
 * @returns {Promise<Object>}
 */
const downloadBlitzFile = async (downloadUrl) => {
    return fetch(downloadUrl, {
        method: 'GET',
        }
    ).then(function(response) {
        if (!response.ok) {
            const error = response.statusText
            return Promise.reject(error)
        }
        const contentLength = response.headers.get('Content-Length')
        console.log('downloadBlitzFile downloaded blitz content length' + contentLength)
        return response.blob()
    }).then(function(data) {
        console.log('downloadBlitzFile data is ', data)
        return data
    }).catch((error) => {
        console.error("There was an error!", error)
    })
}

/**
 *
 * @param {string} downloadUrl
 * @returns {Promise<Response>}
 */
const downloadWithProgress = async (downloadUrl) => {
    return fetch(downloadUrl, {
            method: 'GET',
        }
    ).then(function(response) {
        if (!response.ok) {
            const error = response.statusText
            return Promise.reject(error)
        }
        const contentLength = response.headers.get('Content-Length')
        const reader = response.body.getReader()
        return store.dispatch('fileDownloadProgress/setTotalByte', parseInt(contentLength)).then((result) => {
            return readChunks(reader)
        })
    }).then(function(data) {
        console.log('downloadBlitzFile data is ', data)
        return data
    }).catch((error) => {
        console.error("There was an error!", error)
    })
}

/**
 *
 * @param contentLength
 * @param reader
 * @param {{total: number, value: number}} downloadStatus
 * @returns {Promise<Blob>}
 */
const readChunks = async (reader) => {
    let receivedLength = 0; // received that many bytes at the moment
    let chunks = []; // array of received binary chunks (comprises the body)
    // console.log('readChunks called with total length ' + downloadStatus.total)
    while(true) {
        const {done, value} = await reader.read();
        if (done) {
            break;
        }
        chunks.push(value);
        receivedLength += value.length
        const status = await store.dispatch('fileDownloadProgress/setByteDownloaded',receivedLength)
        console.log(`readChunks received ${receivedLength}`)
    }
    return new Blob(chunks)
}
const readExcelContents = (excelFileBlob) => {
    return new Promise((resolve,reject) => {
        let fileReader = new FileReader()
        fileReader.onload = (e) => {
            let binary = ''
            let bytes = new Uint8Array(e.target.result)
            let length = bytes.byteLength
            for (let i = 0; i < length; i++) {
                binary += String.fromCharCode(bytes[i])
            }
            // console.log('uploadFile finish reading ' + excelFileBlob.name + ' binary length ' + length)
            // call 'xlsx' to read the file
            let wb = XLSX.read(binary, { type: 'binary', cellDates: true, cellStyles: true })
            const ws = wb.Sheets[wb.SheetNames[0]]
            const rows = XLSX.utils.sheet_to_json(ws, { header: 1, dateNF: 'DD/MM/YYYY' })
            console.log('fileReader completed loading xls with ' + rows.length + ' rows ')
            resolve(rows)
        }
        fileReader.onerror = (error) => {
            reject(error)
        }
        fileReader.readAsArrayBuffer(excelFileBlob)
    })
}



const FetchReportHelper = {
    /**
     *
     * @param {string} accessKey
     * @returns {Promise<{signInToken: string, fileName: string}>}
     */
    async getLoginTokenInfo(accessKey) {
        return getLoginToken(accessKey).then((tokenInfo) => {
            return tokenInfo
        }).catch((error)=>{
            throw error
        })
    },
    /**
     * 
     * @param {string} accessKey 
     * @returns 
     */
    async checkExpiredLink(accessKey) {
        return checkLink(accessKey).then((result) => {
            return result
        }).catch((error)=>{
            throw error
        })
    },
    /**
     *
     * @param {{signInToken: string, fileName: string }} token
     * @returns {Promise<firebase.auth.UserCredential>}
     */
    async loginToPortalWithToken(token) {
        return loginWithToken(token.signInToken)
    },
    /**
     *
     * @param {string} fileName
     * @param {string} accessKey
     * @returns {Promise<{fileName: *, rows: unknown}>}
     */
    async downloadBlitzFile(fileName, accessKey) {
        // const fileName = 'BLITZ 202104.xls'
        const signedUrl = await getDownloadUrl(accessKey)
        // const hardCodedUrl = 'https://firebasestorage.googleapis.com/v0/b/bv-customer-files/o/bhp%2F7%20Eleven%20Stores%20BLITZ%20160818.xlsx?alt=media&token=1f29d009-609a-4aa5-851e-94510dfeb36f'
        const excelFileBuffer = await downloadBlitzFile(signedUrl)
        const file = new File([excelFileBuffer], fileName, {type: excelFileBuffer.type, lastModified: Date.now()});
        console.log('downloadBlitzFile blitz file created ', file)
        return readExcelContents(file).then((rows) => {
            console.log('downloadBlitzFile returning ' + rows.length + ' excel rows')
            return { fileName: fileName, rows: rows}
        })
    },
    /**
     *
     * @param fileName
     * @param accessKey
     * @returns {Promise<{fileName: *, rows: unknown}>}
     */
    async downloadBlitzFileWithProgress(fileName, accessKey) {
        // const fileName = 'BLITZ 202104.xls'
        const signedUrl = await getDownloadUrl(accessKey)
        const excelFileBlog = await  downloadWithProgress(signedUrl)
        const file = new File([excelFileBlog], fileName, {type: excelFileBlog.type, lastModified: Date.now()});
        console.log('downloadBlitzFile blitz file created ', file)
        return { fileName: fileName, file: file}
    },


    async convertExceltoArray(fileName, file) {
        return readExcelContents(file).then((rows) => {
            return { fileName: fileName, rows: rows}
        })
    },


}

export default FetchReportHelper
