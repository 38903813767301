import { set, toggle } from '@/utils/vuex'

export default {
  setDrawer: set('drawer'),
  setBarImage: set('barImage'),
  setScrim: set('barColor'), // SET_SCRIM
  toggleDrawer: toggle('drawer'),
  setProgress: set('progress'),
  setCustomerInfo: set('customerInfo'),
  setRefreshRequired: set('refreshRequired'),
  setGlobalFilter: set('globalFilter'),
  setApplyingGlobalFilter: set('applyingGlobalFilter'),
  setDataLoaded: set('dataLoaded'),
  setDataLoadRequired: set('dataLoadRequired'),
}
