import {
  getSnUsageMonthDb,
  getSnDb,
  getEquipmentSummaryDb,
  getCustomerInfo,
  getSnCustomerInfoDb,
} from './PouchDao'

import moment from 'moment'
import { isEmpty } from 'lodash'

/**
 * all in one function to prepare dashboard.
 * this function is called from dashboard/action.js loadData() function
 *
 * @returns {Promise<void>}
 */
async function prepareDashboard () {
  // console.log('prepareDashboard started')
  let currentMonthTotalPromise = getCustomerInfo().then(customerInfo => {
    const latest = customerInfo.latest
    const earliest = customerInfo.earliest
    // console.log('prepareDashboard() obtained customerInfo currentMonth is ' + latest + ' earliest is ' + earliest)

    //
    // the dashboard/action.js loadData() use these values
    //
    let domesticUsageTotal = findDataUsage(latest, 'DOMESTIC DATA').then((result) => {
      // console.log('prepareDashboard() domesticUsageTotal is ', result)
      return result
    })

    let overseasUsageTotal = findDataUsage(latest, 'OVERSEAS DATA').then((result) => {
      return result
    })

    let domesticSmsTotal = countAllDomesticSMS(latest).then((result) => {
      return result
    })
    let domesticVoiceTotal = countAllDomesticVoice(latest).then((result) => {
      return result
    })
    let currentMonthBillTotal = findBillTotal(latest).then((result) => {
      return result
    })
    const previousMonth = moment(latest + '01', 'YYYYMMDD').subtract(1, 'months').format('YYYYMM')
    let previousMonthBillTotal = findBillTotal(previousMonth).then((result) => {
      return result
    })
    let billTotalChartPromise = findLast12MonthsBillTotal('BILL', 'TOTAL', earliest, latest).then((result) => {
      return result
    })
    // no longer used
    let domesticVoice12MonthPromise = find12MonthsDomesticVoiceTotal(earliest, latest).then((result) => {
      return result
    })
    // no longer used
    let domesticData12MonthPromise = find12MonthsDomesticDataUsageAndExcessTotal(earliest, latest).then((result) => {
      return result
    })
    let nationalCallSparkLinePromise = find12MonthsNationalCallSparkLine(earliest, latest).then((result) => {
      return result
    })
    let nationalDataSparkLinePromise = find12MonthsNationalDataSparkLine(earliest, latest).then((result) => {
      return result
    })
    let internationalDataSparkLinePromise = find12MonthsInternationalDataSparkLine(earliest, latest).then((result) => {
      return result
    })
    let dataAllowanceTotal = findDataAllowance().then((result) => {
      return result
    })
    // return as currentMonthTotalPromise
    return Promise.all([
      latest,
       domesticUsageTotal,
       overseasUsageTotal,
       domesticSmsTotal,
       domesticVoiceTotal,
       currentMonthBillTotal,
       previousMonthBillTotal,
       billTotalChartPromise,
       domesticVoice12MonthPromise, // no longer used
       domesticData12MonthPromise, // no longer used
       nationalCallSparkLinePromise,
       nationalDataSparkLinePromise,
       internationalDataSparkLinePromise,
       dataAllowanceTotal
    ])
  })

  let mobileCountPromise = countAllSn().then((result) => {
    // console.log('mobileCountPromise result is ' + result.total + ' active ' + result.active)
    return { mobileCount: result.total, currentMonthActive: result.active }
  })
  let findAllAccountPromise = findAllAccount().then((result) => {
    return { acctCount: result }
  })
  let findAllCidnPromise = findAllCidn().then((result) => {
    return { cidnCount: result }
  })

  let findAllDeviceListPromise = findDeviceList().then((result) => {
    // console.log('prepareDashboard findAllDeviceListPromise completed')
    return result
  })
  return Promise.all([currentMonthTotalPromise,
    mobileCountPromise,
    findAllAccountPromise,
    findAllCidnPromise,
    findAllDeviceListPromise,
    // topTens,
  ])
}

/**
 * returns an array of deviceType, make, model aka deviceName and qty
 * @param startYearMonth
 * @param endYearMonth
 * @returns {Promise<*[]|any|u>}
 */
async function findDeviceList (startYearMonth, endYearMonth) {
  // console.log('findDeviceList started - finding devices from snDb ')
  return getSnDb()
    .then((snDb) => {
      return snDb.allDocs({ include_docs: true })
    }).then((result) => {
      let devices = []
      result.rows.forEach((row) => {
        // if (row.doc.acct) {
        devices.push({
          model: row.doc.deviceName,
          make: row.doc.make,
          deviceType: row.doc.deviceType,
          qty: 1,
          active: row.doc.latestActive,
          billTotal: row.doc.latestBillTotal,
        })
        // }
      })
      const deviseList = [
        ...devices.reduce(
          (map, item) => {
            const { model: key, qty, active, billTotal } = item
            const prev = map.get(key)
            if (prev) {
              prev.qty += qty
              prev.active += active
              prev.billTotal += billTotal
            } else {
              map.set(key, Object.assign({}, item))
            }
            return map
          },
          new Map(),
        ).values(),
      ]
      // console.log('findDeviceList ended', deviseList)
      return { deviceList: deviseList }
    })
}

/**
 * Get all SN just to init the Servicenumber table in the page
 * @returns {Promise<void>}
 */
// let options = {include_docs: true};
/*

startkey: the _id of the first doc we'd like to fetch
endkey: the _id of the last doc we'd like to fetch
limit: maximum number of docs to return
skip: number of docs to skip
descending: true if we want reverse ordering

*/
async function findAllSnPagination (page, itemsPerPage, forward, startkey) {
  let allSnOptions = { limit: 10, include_docs: true } // this used to be a global variable located outside this function
  // console.log('page ' + page + ' itemsPerPage ' + itemsPerPage + ' forward ' + forward + ' startkey ' + startkey, allSnOptions)
  // options.limit = itemsPerPage
  if (startkey && startkey.length > 0) {
    allSnOptions = { limit: itemsPerPage, startkey: startkey, include_docs: true, skip: 1 }
  } else {
    // console.log('reset options to default since startkey  is empty')
    allSnOptions = { limit: itemsPerPage, include_docs: true }
  }
  if (forward) {
    allSnOptions.descending = false
  } else {
    allSnOptions.descending = true
  }
  // snDb.allDocs(options).then()
  return getSnDb()
    .then((snDb) => {
      // eslint-disable-next-line handle-callback-err
      return snDb.allDocs(allSnOptions, (err, response) => {
        if (response && response.rows.length > 0) {
          /**
           * for some reason the last row is a class id !? we need
           */
          let lastRow = response.rows[response.rows.length - 1]
          if (lastRow.doc.sn) {
            allSnOptions.startkey = response.rows[response.rows.length - 1].id
          } else {
            allSnOptions.startkey = response.rows[0].id // we have already reach the last page hence the last key is the first key on the last page.
          }
          allSnOptions.skip = 1
        }
        return response
        // handle err or response
      })
    }).then((result) => {
      // store this in the state snservice module
      const snNumbers = []
      result.rows.forEach(function (snNumberRow) {
        snNumbers.push(snNumberRow.doc)
      })
      // store.dispatch("snService/saveResult",{data: snNumbers, rowCount: result.total_rows});
      // console.log('findAllSn()  return total rows ' + result.total_rows + ' and ' + snNumbers.length + ' sn rows start key ' + allSnOptions.startkey)
      return { snNumbers: snNumbers, total: result.total_rows, startkey: allSnOptions.startkey }
    })
}

/**
 * can pass option { include_docs: true } to get all data 
 * if don't pass option it will return indexes which is so quicker
 * @param {*} options 
 * @returns 
 */
async function findAllSn (options = {}) {
  return getSnDb()
    .then((snDb) => {
      // eslint-disable-next-line handle-callback-err
      return snDb.allDocs( options , (err, response) => {
        return response
        // handle err or response
      })
    }).then((result) => {
      // store this in the state snservice module
      const snNumbers = []
      result.rows.forEach(function (snNumberRow) {
        snNumbers.push(snNumberRow.doc)
      })
      return snNumbers
    })
}

/**
 * returns the total count and number of active
 * @returns {Promise<PouchDB>}
 */
async function countAllSn () {
  // console.log('countAllSn started')
  return getSnDb()
    .then((snDb) => {
      return snDb.allDocs({ include_docs: true })
    }).then((result) => {
      const activeCount = result.rows.filter(snNumberRow => snNumberRow.doc.latestActive).length
      return { total: result.rows.length, active: activeCount }
    }).catch(function (err) {
      console.error(err)
    })
}

async function findSn (sn) {
  // console.log('findSn ' + sn + ' started')
  return getSnDb()
    .then((snDb) => {
      return snDb.allDocs({ key: sn, include_docs: true, limit: 1 })
    }).then(allDocs => {
      // console.log('findSn ' + sn + ' ended found ' + allDocs.rows.length + ' row')
      if (allDocs.rows.length > 0) {
        return allDocs.rows[0].doc
      } else {
        return {}
      }
    })
}

/**
 * returns a list of unique accounts
 * @returns {Promise<number|*|u>}
 */
async function findAllAccount () {
  // console.log('findAllAccount started')
  return getSnDb()
    .then((snDb) => {
      return snDb.allDocs({ include_docs: true })
    }).then((result) => {
      let accounts = []
      result.rows.forEach((row) => {
        if (row.doc.acct) {
          accounts.push({ acct: row.doc.acct, qty: 1 })
        }
      })
      const sums = [
        ...accounts.reduce(
          (map, item) => {
            const { acct: key, qty } = item
            const prev = map.get(key)
            if (prev) {
              prev.qty += qty
            } else {
              map.set(key, Object.assign({}, item))
            }
            return map
          },
          new Map(),
        ).values(),
      ]
      // console.log('findAllAccount ended found ' + sums.length)
      return sums.length
    })
}

async function findAllCidn () {
  // console.log('findAllCidn started')
  return getSnDb()
    .then((snDb) => {
      return snDb.allDocs({ include_docs: true })
    }).then((result) => {
      let accounts = []
      result.rows.forEach((row) => {
        if (row.doc.acct && row.doc.cidn && row.doc.cidn > 0) {
          accounts.push({ cidn: row.doc.cidn, qty: 1 })
        }
      })
      const sums = [
        ...accounts.reduce(
          (map, item) => {
            const { cidn: key, qty } = item
            const prev = map.get(key)
            if (prev) {
              prev.qty += qty
            } else {
              map.set(key, Object.assign({}, item))
            }
            return map
          },
          new Map(),
        ).values(),
      ]
      // console.log('findAllCidn ended found ' + sums.length)
      return sums.length
    })
}

async function getTopTenInfo () {
  return getSnCustomerInfoDb().allDocs({ include_docs: true, key: 'topTenInfo', limit: 1 })
    .then((result) => {
      if (result.rows.length > 0) {
        return result.rows[[0]].doc
      } else {
        // console.warn('no topTenInfo recorded')
        return {}
      }
    })
}

async function getDashboardInfo () {
  // console.log(' getDashboardInfo getting dashboardInfo from snCustomerInfoDb started')
  return getSnCustomerInfoDb().allDocs({ include_docs: true, key: 'dashboardInfo', limit: 1 })
    .then((result) => {
      if (result.rows.length > 0) {
        return result.rows[[0]].doc
      } else {
        // console.warn('no dashboardInfo recorded returning null')
        return null
      }
    })
}

async function prepareTopTenTable () {
  // console.log('prepareTopTenTable started')
  let currentMonthTotalPromise = queryLatestMonth().then((currentMonth) => {
    let topTens = topTensChart(currentMonth.latest).then(([domesticCallTop10, domesticUsageTop10, domesticBillTotalTop10]) => {
      return {
        domesticCallTop10: domesticCallTop10,
        domesticUsageTop10: domesticUsageTop10,
        // domesticDurationTop10: domesticDurationTop10,
        domesticBillTotalTop10: domesticBillTotalTop10
      }
    })
    let topTensOverseas = topTensOverseasChart(currentMonth.latest).then(([overseasCallTop10]) => {
      return { overseasCallTop10: overseasCallTop10 }
    })
    let topTenDomesticVoice = topTensDomesticVoiceChart(currentMonth.latest).then(([domesticVoiceCallTopTen, domesticVoiceDurationTopTen]) => {
      return {
        domesticVoiceCallTop10: domesticVoiceCallTopTen,
        domesticVoiceDurationTop10: domesticVoiceDurationTopTen,
      }
    })
    return Promise.all([topTens, topTenDomesticVoice, topTensOverseas])
  })
  return currentMonthTotalPromise
}

/**
 * get the last bill month
 * do a sort desc and pick the first row
 * @returns {Promise<*>}
 */
async function queryLatestMonth () {
  // console.log('queryLatestMonth started ')
  return getCustomerInfo().then(customerInfo => {
    return { latest: customerInfo.latest, earliest: customerInfo.earliest }
  })
}

async function getTopTenForMonth (category, dataType, yearMonth) {
  return getSnUsageMonthDb().then((snUsageMonthDb) => {
    return snUsageMonthDb.allDocs({
      startkey: category + '-' + dataType,
      endkey: category + '-' + dataType + '\ufff0',
      include_docs: true,
    })
  }).then((result) => {
    // console.log('getTopTenForMonth ' + category + ' dataType ' + dataType + ' returned ' + result.rows.length + ' rows ')
    const domesticCallCount = result.rows.map((row) => row.doc)
      .reduce((acc, call) => {
        if (call[yearMonth] > 0) {
          if (acc.get(call.sn)) {
            acc.set(call.sn, acc.get(call.sn) + call[yearMonth])
          } else {
            acc.set(call.sn, call[yearMonth])
          }
        }
        return acc // return the accumulator
      }, new Map())
    // console.log('getTopTenForMonth ' + category + ' dataType ' + dataType + ' returned domesticCallCount', domesticCallCount)
    let domesticCallCountTop10 = getTopTen(domesticCallCount) // returns a list of {sm: , qty}
    // loop through list to find the customer name
    let topTenPromisesList = []
    domesticCallCountTop10.forEach(topTen => {
      topTenPromisesList.push(findSn(topTen.sn).then(snRow => {
        // console.log('findSn for Top ten returns ')
        // console.log(snRow)
        return { sn: snRow.sn, qty: topTen.qty, userName: snRow.userName }
      }))
    })
    return Promise.allSettled(topTenPromisesList).then(results => {
      // process the result list
      let finalTopTenList = []
      results.forEach(result => {
        finalTopTenList.push(result.value) // promise.allSettled has a status and value property
      })
      // console.log('getTopTenForMonth ' + category + ' dataType ' + dataType + ' completed ', finalTopTenList)
      return finalTopTenList
    })
    // return domesticCallCountTop10
  })
}

//
// top ten domestic data chart
//
async function topTensChart (yearMonth) {
  // console.log('topTensChart started for ' + yearMonth)
  const domesticCallCountTop10 = getTopTenForMonth('DOMESTIC-DATA', 'CALL', yearMonth)
  const domesticUsageTop10 = getTopTenForMonth('DOMESTIC-DATA', 'USAGE', yearMonth)
  // let domesticDurationTop10 = getTopTenForMonth('DOMESTIC-DATA', 'DURATION', yearMonth)
  const domesticBillTotalTop10 = getTopTenForMonth('BILL', 'TOTAL', yearMonth)
  return Promise.all([domesticCallCountTop10, domesticUsageTop10, domesticBillTotalTop10])
}

async function topTensOverseasChart (yearMonth) {
  // console.log('topTensOverseasChart started for ' + yearMonth)
  let overseasCallCountTop10 = getTopTenForMonth('OVERSEAS-DATA', 'CALL', yearMonth)
  console.log('topTensOverseasChart ended for ' + yearMonth + ' found ' + overseasCallCountTop10.length + ' top ten numbers')
  return Promise.all([overseasCallCountTop10])
}

// only have calls and minutes of use aka duration for domestic voice
async function topTensDomesticVoiceChart (yearMonth) {
  // console.time('topTensDomesticVoiceChart')
  // console.log('topTensDomesticVoiceChart started for ' + yearMonth)
  let domesticVoiceCallTopTen = getTopTenForMonth('DOMESTIC-VOICE', 'CALL', yearMonth)
  let domesticVoiceDurationTopTen = getTopTenForMonth('DOMESTIC-VOICE', 'DURATION', yearMonth)
  return Promise.all([domesticVoiceCallTopTen, domesticVoiceDurationTopTen])
}

/**
 * return a list of result order by descending value
 * the result list item is of type sn: asads, qty: 122
 * @param snUsageMap
 * @returns {{qty: *, sn: *}[]}
 */
function getTopTen (snUsageMap) {
  return Array.from(snUsageMap).map(([key, value]) => {
    return { sn: key, qty: value }
  })
    .sort((sn1, sn2) => sn2.qty - sn1.qty)
    .slice(0, 10)
}

// Category is DOMESTIC VOICE with the space
// to get total VOICE calls , total VOICE minutes of use...
async function countAllDomesticVoice (yearMonth) {
  const category = 'DOMESTIC VOICE'
  // console.log('countAllDomesticVoice for ' + yearMonth + ' category ' + category + ' started')
  let callPromise = findMonthlyDataTable(category, 'CALL').then(result => {
    let avg3Months = 0.0
    let totalSum = 0
    let usageCount = 0
    result.forEach((row) => {
      if (row.doc[yearMonth] > 0) {
        const previousMonth1 = moment(yearMonth + '01', 'YYYYMMDD').subtract(1, 'months').format('YYYYMM')
        const previousMonth2 = moment(yearMonth + '01', 'YYYYMMDD').subtract(2, 'months').format('YYYYMM')
        totalSum += row.doc[yearMonth]
        const avg3MonthsCalc = (row.doc[yearMonth] + row.doc[previousMonth1] + row.doc[previousMonth2]) / 3
        avg3Months += Math.floor(avg3MonthsCalc) // not sure if we need only do this when year month > 0
        usageCount++
      }
    })
    // console.log('findDataUsage ' + category + ' call ended totalSum ' + ' for ' + yearMonth + ' is ' + totalSum)
    return { totalSum: totalSum, usageCount: usageCount, totalAvg3Months: avg3Months }
  })
  let durationPromise = findMonthlyDataTable(category, 'DURATION').then(result => {
    let avg3Months = 0.0
    let totalSum = 0
    result.forEach((row) => {
      if (row.doc[yearMonth] > 0) {
        avg3Months += row.doc.avg3Months // not sure if we need only do this when year month > 0
        totalSum += row.doc[yearMonth]
      }
    })
    // console.log('findDataUsage ' + category + ' duration ended totalSum ' + ' for ' + yearMonth + ' is ' + totalSum)
    return { totalSum: totalSum, avg3Months: avg3Months }
  })
  return Promise.all([callPromise, durationPromise]).then(([callP, durationP]) => {
    return {
      callTotal: callP.totalSum,
      callTotalAvg3Months: callP.totalAvg3Months,
      callCount: callP.usageCount,
      durationTotal: durationP.totalSum,
      durationAvg3Months: durationP.avg3Months,
    }
  })
}

// Category is DOMESTIC SMS with the space
// Domestic SMS only has one measure, there is no usage, duration nor excess...
// yearMonth is YYYYMM format eg 202005
async function countAllDomesticSMS (yearMonth) {
  const category = 'DOMESTIC SMS'
  // console.log('countAllDomesticSMS for ' + yearMonth + ' category ' + category + ' started')
  let callPromise = findMonthlyDataTable(category, null).then(result => {
    let avg3Months = 0.0
    let totalSum = 0
    let usageCount = 0
    result.forEach((row) => {
      if (row.doc[yearMonth] > 0) {
        const previousMonth1 = moment(yearMonth + '01', 'YYYYMMDD').subtract(1, 'months').format('YYYYMM')
        const previousMonth2 = moment(yearMonth + '01', 'YYYYMMDD').subtract(2, 'months').format('YYYYMM')
        totalSum += row.doc[yearMonth]
        const avg3MonthsCalc = (row.doc[yearMonth] + row.doc[previousMonth1] + row.doc[previousMonth2]) / 3
        avg3Months += Math.floor(avg3MonthsCalc) // not sure if we need only do this when year month > 0
        usageCount++
      }
    })
    // console.log('findDataUsage ' + category + ' call ended totalSum ' + ' for ' + yearMonth + ' is ' + totalSum)
    return { totalSum: totalSum, usageCount: usageCount, usageAvg3Months: avg3Months }
  })
  return Promise.all([callPromise]).then(([callP]) => {
    return { callTotal: callP.totalSum, callCount: callP.usageCount, callAvg3Months: callP.usageAvg3Months }
  })
}

async function findDataAllowance () {
  return getSnDb().then((snDb) => {
    return snDb.allDocs({
      include_docs: true,
    })
  }).then((result) => {
    let total = 0
    result.rows.forEach((row) => {
        total += (row.doc['totalDataAllowance'] ? parseInt(row.doc['totalDataAllowance']) : 0)
        total += (row.doc['bonusDataAllowance'] ? parseInt(row.doc['bonusDataAllowance']) : 0)
    })
    console.log('total data allowance is : ' + total)
    return { allowanceTotal: total }
  })      
}

async function findLast12MonthsDomesticDataTotal (startYearMonth, endYearMonth) {
  // group the billtotal by months and get the total...
  // console.log('findLast12MonthsDomesticDataTotal started for ' + startYearMonth + ' to ' + endYearMonth)
  return createMonthRange(startYearMonth, endYearMonth).then((result) => {
    const monthList = result // array of ['201801, '201802'...]
    // domestic voice has call and duration meature
    let totalCall = find12MonthsTotal('DOMESTIC DATA', 'CALL', monthList)
    let totalDuration = find12MonthsTotal('DOMESTIC DATA', 'DURATION', monthList)
    let totalUsage = find12MonthsTotal('DOMESTIC DATA', 'USAGE', monthList)
    let totalExcess = find12MonthsTotal('DOMESTIC DATA', 'EXCESS', monthList)
    return Promise.all([totalCall, totalDuration, totalUsage, totalExcess]).then(([totalCall, totalDuration, totalUsage, totalExcess]) => {
      // console.log('findLast12MonthsDomesticDataTotal completed ' + startYearMonth + ' ending ' + endYearMonth)
      return { totalCall: totalCall, totalDuration: totalDuration, totalUsage: totalUsage, totalExcess: totalExcess }
    })
  })
}

async function findLast12MonthsDomesticDataPlanUsageTotal (startYearMonth, endYearMonth, planName) {
  // group the billtotal by months and get the total...
  console.log('findLast12MonthsDomesticDataPlanUsageTotal started for ' + startYearMonth + ' to ' + endYearMonth, planName)
  return createMonthRange(startYearMonth, endYearMonth).then((result) => {
    const monthList = result // array of ['201801, '201802'...]
    // domestic voice has call and duration meature
    let planTotalResult = find12MonthsPlanTotal('DOMESTIC DATA', 'USAGE', monthList, planName)
    return Promise.all([planTotalResult]).then(([planTotalResult]) => {
      // console.log('findLast12MonthsDomesticDataTotal completed ' + startYearMonth + ' ending ' + endYearMonth)
      return { totalPlanCapacity: planTotalResult.totalPlanCapacity, totalUsage: planTotalResult.totalUsage }
    })
  })
}

/**
 * there are only CALLS and MINUTES OF USE for domestic voice
 * @param startYearMonth
 * @param endYearMonth
 * @param sn
 * @returns {Promise<{callCountList: *}>}
 */
async function findLast12MonthsDomesticVoiceSn (startYearMonth, endYearMonth, sn) {
  // group the billtotal by months and get the total...
  // console.log('findLast12MonthsDomesticVoiceSn ' + sn + ' started for ' + startYearMonth + ' to ' + endYearMonth)
  return createMonthRange(startYearMonth, endYearMonth).then((result) => {
    let voiceTotalMonthPromises = []
    result.forEach((yearMonth) => {
      let voiceTotalMonthPromise = findDomesticVoiceTotalForMonthSn(yearMonth, sn).then((callCountListObject) => {
        // console.log(callCountListObject.callCountList)
        return callCountListObject.callCountList[0]
      })
      voiceTotalMonthPromises.push(voiceTotalMonthPromise)
    })
    return Promise.all(voiceTotalMonthPromises).then((result) => {
      let voiceTotal = []
      result.forEach((voiceTotalMonth) => {
        // console.log(voiceTotalMonthPromise)
        voiceTotal.push(voiceTotalMonth)
      })
      // console.log('findLast12MonthsDomesticVoiceSn ' + sn + ' completed ')
      return { callCountList: voiceTotal }
    })
  })
}

async function findDomesticVoiceTotalForMonthSn (yearMonth, sn) {
  // console.log('findDomesticVoiceTotalForMonthSn for ' + yearMonth + ' sn ' + sn)
  return getSnUsageMonthDb().then((snUsageMonthDb) => {
    return snUsageMonthDb.allDocs({
      startkey: 'DOMESTIC-VOICE-' + sn, // DOMESTIC-VOICE
      endkey: 'DOMESTIC-VOICE-' + sn + '\ufff0',
      include_docs: true,

    }).then((result) => {
      // domestic voice can be either CALL or DURATION
      let domesticVoiceResult = []
      result.rows.forEach((row) => {
        // console.log('findDomesticVoiceTotalForMonthSn for ' + yearMonth + ' sn ' + sn + ' call count is ' + row.doc.call)
        if (row.doc.sn === sn) {
          if (row.doc.dataType === 'CALL') {
            domesticVoiceResult.push({ yearMonth: yearMonth, duration: 0, call: row.doc[yearMonth], snCount: 1 })
          }
          if (row.doc.dataType === 'DURATION') {
            domesticVoiceResult.push({ yearMonth: yearMonth, duration: row.doc[yearMonth], call: 0, snCount: 0 })
          }
        }
      })
      const callCountList = [
        ...domesticVoiceResult.reduce(
          (map, item) => {
            const { yearMonth: key } = item
            const prev = map.get(key)
            if (prev) {
              prev.call += item.call
              prev.duration += item.duration
              prev.snCount += 1
            } else {
              map.set(key, Object.assign({}, item))
            }
            return map
          },
          new Map(),
        ).values(),
      ]
      // console.log('findDomesticVoiceTotalForMonthSn for ' + yearMonth + ' sn ' + sn + ' ended')
      return { callCountList: callCountList }
    })
  })
}

/**
 *  get the 12 month call total
 *
 */
// this is redundant abstraction - TODO remove and call direct
// async function find12MonthsDomesticDataTotalCall(startYearMonth, endYearMonth) {
//   console.log('findLast12MonthsDomesticDataTotal started for ' + startYearMonth + ' to ' + endYearMonth + ' started')
//   return findLast12MonthsDomesticDataTotal(startYearMonth, endYearMonth).then(result => {
//     console.log('findLast12MonthsDomesticDataTotal started for ' + startYearMonth + ' to ' + endYearMonth + ' ended')
//     return result
//   })
// }

// this is redundant abstraction - TODO remove and call direct findLast12MonthsDomesticVoiceTotal
// async function find12MonthsDomesticVoiceTotalCall(startYearMonth, endYearMonth) {
//   console.log('find12MonthsDomesticVoiceTotalCall started for ' + startYearMonth + ' to ' + endYearMonth + ' started')
//   return findLast12MonthsDomesticVoiceTotal(startYearMonth, endYearMonth).then(result => {
//     console.log('find12MonthsDomesticVoiceTotalCall started for ' + startYearMonth + ' to ' + endYearMonth + ' ended')
//     console.log(result)
//     return result
//   })
// }

async function findMonthlyTopTen (category, dataType, yearMonth) {
  // console.log('findMonthlyTopTen for *' + category + '* data type *' + dataType + '* year Month *' + yearMonth + '* started')
  // key is category + data type + sn
  // eg           _id: category.split(' ').join('-') + '-CALL-' + sn,
  return getSnUsageMonthDb().then((snUsageMonthDb) => {
    return snUsageMonthDb.allDocs({
      startkey: category.split(' ').join('-') + '-' + dataType,
      endkey: category.split(' ').join('-') + '-' + dataType + '\ufff0',
      include_docs: true,
    })
  }).then((result) => {
    // console.log('findMonthlyTopTen getSnUsageMonthDb result returned ' + result.rows.length)
    const domesticCallCount = result.rows.map((row) => row.doc)
      .reduce((serviceNumberMap, serviceNumberRow) => {
        // console.log('findMonthlyTopTen processing ' + serviceNumberRow.sn + ' ' + dataType + ' is  ' + serviceNumberRow[yearMonth] + ' for yearMonth ' + yearMonth)
        if (serviceNumberRow[yearMonth] > 0) {
          if (serviceNumberMap.get(serviceNumberRow.sn)) {
            // console.log('findMonthlyTopTen set existing ' + serviceNumberRow.sn + ' value ' + serviceNumberRow[yearMonth])
            serviceNumberMap.set(serviceNumberRow.sn, serviceNumberMap.get(serviceNumberRow.sn) + serviceNumberRow[yearMonth])
          } else {
            // console.log('findMonthlyTopTen put ' + serviceNumberRow.sn + ' value ' + serviceNumberRow[yearMonth])
            serviceNumberMap.set(serviceNumberRow.sn, serviceNumberRow[yearMonth])
          }
        }
        return serviceNumberMap // return the accumulator
      }, new Map())
    let topTenresult = getTopTen(domesticCallCount)
    // console.log('findMonthlyTopTen for ' + category + ' data type ' + dataType + ' year Month ' + yearMonth + ' ended with rows ' + topTenresult.length)
    return topTenresult
  })
}

async function findMonthlyDataTable (category, dataType) {
  console.log('findMonthlyDataTable for getSnUsageMonthDb ' + category + ' data type ' + dataType + ' started')
  return getSnUsageMonthDb().then((snUsageMonthDb) => {
    return snUsageMonthDb.allDocs({
      startkey: dataType ? category.split(' ').join('-') + '-' + dataType : category.split(' ').join('-') + '-',
      endkey: dataType ? category.split(' ').join('-') + '-' + dataType + '\ufff0' : category.split(' ').join('-') + '-' + '\ufff0',
      include_docs: true,
    })
  }).then((result) => {
    // the result will be filtered on category DOMESTIC DATA and data type CALL
    // eg DOMESTIC DATA, CALL, 029120192, Jan 2018, Feb 2018, Mar 2018, Apr 2018.... Dec 2018
    return result.rows
  })
}

async function findMonthlyDataByServiceNumber (category, dataType, sn) {
  // sn = '0408676117'
  if (!sn) {
    console.error('findMonthlyDataByServiceNumber sn is empty!!')
  }
  console.log('findMonthlyDataByServiceNumber for ' + category + ' data type ' + dataType + ' sn ' + sn + ' started')
  return getSnUsageMonthDb().then((snUsageMonthDb) => {
    return snUsageMonthDb.allDocs({
      startkey: category.split(' ').join('-') + '-' + dataType + '-' + sn,
      endkey: category.split(' ').join('-') + '-' + dataType + '-' + sn + '\ufff0',
      include_docs: true,
    })
  }).then((result) => {
    // the result will be filtered on category DOMESTIC DATA and data type CALL
    // eg DOMESTIC DATA, CALL, 029120192, Jan 2018, Feb 2018, Mar 2018, Apr 2018.... Dec 2018
    // console.log(result.rows)
    return result.rows
  })
}

async function find12MonthsOverseasDataTotalCall (startYearMonth, endYearMonth) {
  console.log('find12MonthsOverseasDataTotalCall started for ' + startYearMonth + ' to ' + endYearMonth + ' started')
  return createMonthRange(startYearMonth, endYearMonth).then((result) => {
    const monthList = result // array of ['201801, '201802'...]
    // domestic voice has call and duration meature
    let totalCall = find12MonthsTotal('OVERSEAS DATA', 'CALL', monthList)
    let totalDuration = find12MonthsTotal('OVERSEAS DATA', 'DURATION', monthList)
    let totalUsage = find12MonthsTotal('OVERSEAS DATA', 'USAGE', monthList)
    let totalExcess = find12MonthsTotal('OVERSEAS DATA', 'EXCESS', monthList)
    return Promise.all([totalCall, totalDuration, totalUsage, totalExcess]).then(([totalCall, totalDuration, totalUsage, totalExcess]) => {
      console.log('find12MonthsOverseasDataTotalCall completed ending ' + endYearMonth)
      return { totalCall: totalCall, totalDuration: totalDuration, totalUsage: totalUsage, totalExcess: totalExcess }
    })
  })
}

async function findSMSTotalForMonth (yearMonth) {
  // console.log('findSMSTotalForMonth for ' + yearMonth + ' started')
  // note the snmonth usage key is DOMESTIC-SMS-01202202
  return getSnUsageMonthDb().then((snUsageMonthDb) => {
    return snUsageMonthDb.allDocs({
      startkey: 'DOMESTIC-SMS',
      endkey: 'DOMESTIC-SMS' + '\ufff0',
      include_docs: true,
    })
  }).then(function (result) {
    let dataUsages = []
    result.rows.forEach((row) => {
      dataUsages.push({ yearMonth: yearMonth, sms: row.doc[yearMonth], snCount: 1 })
    })
    const callCountList = [
      ...dataUsages.reduce(
        (map, item) => {
          const { yearMonth: key } = item
          const prev = map.get(key)
          if (prev) {
            prev.sms += item.sms
            prev.snCount += 1
          } else {
            map.set(key, Object.assign({}, item))
          }
          return map
        },
        new Map(),
      ).values(),
    ]
    return { callCountList: callCountList }
  })
}

async function findLast12MonthsSMSTotal (startYearMonth, endYearMonth) {
  // group the billtotal by months and get the total...
  console.log('findLast12MonthsSMSTotal started for ' + startYearMonth + ' to ' + endYearMonth)
  return createMonthRange(startYearMonth, endYearMonth).then((result) => {
    let dataTotalMonths = []
    result.forEach((yearMonth) => {
      let dataTotalMonthPromise = findSMSTotalForMonth(yearMonth).then((callCountListObject) => {
        // console.log(callCountListObject.callCountList)
        return callCountListObject.callCountList[0]
      })
      dataTotalMonths.push(dataTotalMonthPromise)
    })
    return Promise.all(dataTotalMonths).then((result) => {
      let dataTotal = []
      result.forEach((dataTotalMonth) => {
        // console.log(dataTotalMonth)
        dataTotal.push(dataTotalMonth)
      })
      return { callCountList: dataTotal }
    })
  })
}

async function find12MonthsSMSTotalCall (startYearMonth, endYearMonth) {
  console.log('find12MonthsSMSTotalCall started for ' + startYearMonth + ' to ' + endYearMonth + ' started')
  return findLast12MonthsSMSTotal(startYearMonth, endYearMonth).then(result => {
    console.log('find12MonthsSMSTotalCall started for ' + startYearMonth + ' to ' + endYearMonth + ' ended')
    return result
  })
}

async function findTopTenEquipment (dataCategory) {
  return getSnDb()
    .then((snDb) => {
      return snDb.allDocs({ include_docs: true })
    }).then((result) => {
      const deviceCount = result.rows.map((row) => row.doc)
        .reduce((acc, it) => {
          if (acc.get(it[dataCategory])) {
            acc.set(it[dataCategory], acc.get(it[dataCategory]) + 1)
          } else {
            acc.set(it[dataCategory], 1)
          }
          return acc // return the accululator
        }, new Map())

      const topTenresult = getTopTen(deviceCount)
      return topTenresult
    })
}

/**
 * find top ten with filter
 * @param dataCategory
 * @param equipmentChartFilter { key: null, deviceType: null, make: null, deviceName: null },
 * @returns {Promise<{qty: *, sn: *}[]>}
 */
async function filterTopTenEquipmentChart (dataCategory, equipmentChartFilter) {
  return getSnDb()
    .then((snDb) => {
      return snDb.allDocs({ include_docs: true })
    }).then((result) => {
      const key = dataCategory
      const deviceCount = result.rows.map((row) => row.doc)
        .filter((row) => {
          let match = true
          if (equipmentChartFilter.deviceType && row.deviceType !== equipmentChartFilter.deviceType) {
            match = false
          }
          if (equipmentChartFilter.make && row.make !== equipmentChartFilter.make) {
            match = false
          }
          if (equipmentChartFilter.deviceName && row.deviceName !== equipmentChartFilter.deviceName) {
            match = false
          }
          return match
        })
        .reduce((acc, it) => {
          // check if row match
          // if key == deviceType
          // then ww want to filter by make
          // console.log('key ' + key, it[key])
          const deviceKey = it[key]
          if (acc.get(deviceKey)) {
            acc.set(deviceKey, acc.get(deviceKey) + 1)
          } else {
            // console.log('setting acc with key ', deviceKey)
            acc.set(deviceKey, 1)
          }
          // console.log('device key ' + deviceKey, it)
          return acc // return the accumulator
        }, new Map())
      // console.log('device Count', deviceCount)
      const topTenresult = getTopTen(deviceCount)
      return topTenresult
    })
}

// returns a list of yearMonth , number
async function findLast12MonthsBillTotal (dataCategory, dataType, startYearMonth, endYearMonth) {
  console.log('findLast12MonthsBillTotal started ' + startYearMonth + ' ending ' + endYearMonth)
  return createMonthRange(startYearMonth, endYearMonth).then((result) => {
    const monthList = result // array of ['201801, '201802'...]
    // console.log('findLast12MonthsBillTotal monthList contains', monthList)
    return find12MonthsTotal(dataCategory, dataType, monthList).then(result => {
      console.log('findLast12MonthsBillTotal completed ' + startYearMonth + ' ending ' + endYearMonth)
      // console.log(result)
      return { billList: result }
    })
  })
}

/**
 * return {callTotal: result}
 * @param startYearMonth
 * @param endYearMonth
 * @returns {Promise<{callTotal: *}>}
 */
async function findLast12MonthsDomesticVoiceTotal (startYearMonth, endYearMonth) {
  // group the billtotal by months and get the total...
  console.log('findLast12MonthsDomesticVoiceTotal started for ' + startYearMonth + ' to ' + endYearMonth)
  return createMonthRange(startYearMonth, endYearMonth).then((result) => {
    const monthList = result // array of ['201801, '201802'...]
    let totalCall = find12MonthsTotal('DOMESTIC VOICE', 'CALL', monthList)
    let totalDuration = find12MonthsTotal('DOMESTIC VOICE', 'DURATION', monthList)
    return Promise.all([totalCall, totalDuration]).then(([totalCall, totalDuration]) => {
      console.log('findLast12MonthsDomesticVoiceTotal completed ' + startYearMonth + ' ending ' + endYearMonth)
      return {totalCall: totalCall, totalDuration: totalDuration}
    })
  })
}

// const monthYearPattern = /^((20)\d{4}$)/
/**
 *
 * @param category
 * @param dataType
 * @param monthRangeList
 * @returns {Promise<*>} a list of [{yearMonth: '201801', total: 1202}, {yearMonth: '201802', total: 1201}]
 */
async function find12MonthsTotal (category, dataType, monthRangeList) {
  console.log('find12MonthsTotal started for  ' + category + ' dataType ' + dataType + ' monthlist contains ' + monthRangeList.length)
  return getSnUsageMonthDb().then((snUsageMonthDb) => {
    return snUsageMonthDb.allDocs({
      startkey: dataType ? category.split(' ').join('-') + '-' + dataType : category.split(' ').join('-') + '-',
      endkey: dataType ? category.split(' ').join('-') + '-' + dataType + '\ufff0' : category.split(' ').join('-') + '-' + '\ufff0',
      // startkey: category.split(' ').join('-') + '-' + dataType,
      // endkey: category.split(' ').join('-') + '-' + dataType + '\ufff0',
      include_docs: true,
    })
  }).then((result) => {
    let dataHeaders = []
    let seedTotal = category === 'BILL' | category === 'NOUSAGE' ? 0.00 : 0
    monthRangeList.forEach(yearMonth => {
      // console.log('adding ' + yearMonth + ' to data headers ')
      dataHeaders.push({ yearMonth: yearMonth, total: seedTotal })
    })
    // 12 months chart result is
    // series [ {jan2018, 100}, {feb2018, 110}, {mar2018, 120}....]
    let monthlyTotalMap = new Map()
    result.rows.map((row) => row.doc).forEach(serviceNumberRow => {
      // for each row add up the qty
      dataHeaders.forEach(yearMonthItem => {
        const yearMonth = yearMonthItem.yearMonth
        //console.log('find12MonthsTotal iterating sn row ' + serviceNumberRow.sn + ' for year month ' + yearMonth)
        if (serviceNumberRow[yearMonth] > 0) {
          if (monthlyTotalMap.get(yearMonth)) { // check if yearMonth exists in map
            //console.log('find12MonthsTotal add to existing ' + yearMonth + ' value ' + serviceNumberRow[yearMonth])
            monthlyTotalMap.set(yearMonth, monthlyTotalMap.get(yearMonth) + serviceNumberRow[yearMonth])
          } else {
            //console.log('find12MonthsTotal put ' + yearMonth + ' value ' + serviceNumberRow[yearMonth])
            monthlyTotalMap.set(yearMonth, serviceNumberRow[yearMonth])
          }
        }
      })
    })
    // now we have accumulated the total in the map.. assign it back to the list
    for (let yearMonthColumn of dataHeaders) {
      if (monthlyTotalMap.get(yearMonthColumn.yearMonth)) {
        yearMonthColumn.total = monthlyTotalMap.get(yearMonthColumn.yearMonth)
      }
    }
    console.log('find12MonthsTotal completed for ' + category + ' dataType ' + dataType)
    return dataHeaders
  })
}

/**
 *
 * @param category
 * @param dataType
 * @param monthRangeList
 * @returns {Promise<*>} a list of [{yearMonth: '201801', total: 1202}, {yearMonth: '201802', total: 1201}]
 */
async function find12MonthsPlanTotal (category, dataType, monthRangeList, planName) {
  console.log('find12MonthsPlanTotal started for  ' + category + ' dataType ' + dataType, planName)
  return getSnUsageMonthDb().then((snUsageMonthDb) => {
    return snUsageMonthDb.allDocs({
      startkey: dataType ? category.split(' ').join('-') + '-' + dataType : category.split(' ').join('-') + '-',
      endkey: dataType ? category.split(' ').join('-') + '-' + dataType + '\ufff0' : category.split(' ').join('-') + '-' + '\ufff0',
      // startkey: category.split(' ').join('-') + '-' + dataType,
      // endkey: category.split(' ').join('-') + '-' + dataType + '\ufff0',
      include_docs: true,
    })
  }).then((result) => {
    let dataHeaders = []
    let seedTotal = category === 'BILL' ? 0.00 : 0
    monthRangeList.forEach(yearMonth => {
      // console.log('adding ' + yearMonth + ' to data headers ')
      dataHeaders.push({ yearMonth: yearMonth, total: seedTotal })
    })
    // 12 months chart result is
    // series [ {jan2018, 100}, {feb2018, 110}, {mar2018, 120}....]
    let monthlyTotalMap = new Map()    
    // let totalPlanCapacityMap = new Map()
    let totalPlanCapacity = 0
    if (planName == 'All Plans') {
      result.rows.map((row) => row.doc).forEach(serviceNumberRow => {
        if (serviceNumberRow.totalDataAllowance) {
          totalPlanCapacity += serviceNumberRow.totalDataAllowance
        }
        if (serviceNumberRow.bonusDataAllowance) {
          totalPlanCapacity += serviceNumberRow.bonusDataAllowance
        }
        dataHeaders.forEach(yearMonthItem => {
          const yearMonth = yearMonthItem.yearMonth
          if (serviceNumberRow[yearMonth] > 0) {
            if (monthlyTotalMap.get(yearMonth)) { // check if yearMonth exists in map
              monthlyTotalMap.set(yearMonth, monthlyTotalMap.get(yearMonth) + serviceNumberRow[yearMonth])
            } else {
              monthlyTotalMap.set(yearMonth, serviceNumberRow[yearMonth])
            }
          }
        })
      })
    }
    else if (planName == 'Shareable Plans') {
      result.rows.map((row) => row.doc).forEach(serviceNumberRow => {
        const shareablePlanName = serviceNumberRow.shareablePlanName
        
        if (shareablePlanName) {
          if (serviceNumberRow.totalDataAllowance) {
            totalPlanCapacity += serviceNumberRow.totalDataAllowance
          }
          if (serviceNumberRow.bonusDataAllowance) {
            totalPlanCapacity += serviceNumberRow.bonusDataAllowance
          }
          dataHeaders.forEach(yearMonthItem => {
            const yearMonth = yearMonthItem.yearMonth
            if (serviceNumberRow[yearMonth] > 0) {
              if (monthlyTotalMap.get(yearMonth)) { // check if yearMonth exists in map
                monthlyTotalMap.set(yearMonth, monthlyTotalMap.get(yearMonth) + serviceNumberRow[yearMonth])             
              } else {
                monthlyTotalMap.set(yearMonth, serviceNumberRow[yearMonth])
              }
            }
          })
        }
      })
    }
    else if (planName == 'Non Shareable Plans') {
      result.rows.map((row) => row.doc).forEach(serviceNumberRow => {
        const nonShareablePlanName = serviceNumberRow.nonShareablePlanName
        
        if (nonShareablePlanName) {
          if (serviceNumberRow.totalDataAllowance) {
            totalPlanCapacity += serviceNumberRow.totalDataAllowance
          }
          if (serviceNumberRow.bonusDataAllowance) {
            totalPlanCapacity += serviceNumberRow.bonusDataAllowance
          }
          dataHeaders.forEach(yearMonthItem => {
            const yearMonth = yearMonthItem.yearMonth
            if (serviceNumberRow[yearMonth] > 0) {
              if (monthlyTotalMap.get(yearMonth)) { // check if yearMonth exists in map
                monthlyTotalMap.set(yearMonth, monthlyTotalMap.get(yearMonth) + serviceNumberRow[yearMonth])             
              } else {
                monthlyTotalMap.set(yearMonth, serviceNumberRow[yearMonth])
              }
            }
          })
        }
      })
    }
    else{
      result.rows.map((row) => row.doc).forEach(serviceNumberRow => {
        // for each row add up the qty
        const flexPlanName = serviceNumberRow.flexiPlanName
        const shareablePlanName = serviceNumberRow.shareablePlanName
        const nonShareablePlanName = serviceNumberRow.nonShareablePlanName
        let match = false
        if (flexPlanName && flexPlanName === planName) {
          // console.log('find12MonthsPlanTotal  ' + serviceNumberRow.sn + ' matches flexplan ' + flexPlanName + ' ' + planName)
          match = true
        }
        if (shareablePlanName && shareablePlanName === planName) {
          // console.log('find12MonthsPlanTotal  ' + serviceNumberRow.sn + ' matches shareable plan ' + shareablePlanName + ' ' + planName)
          match = true
        }
        if (nonShareablePlanName && nonShareablePlanName === planName) {
          //console.log('find12MonthsPlanTotal  ' + serviceNumberRow.sn + ' matches nonshareable plan ' + nonShareablePlanName + ' ' + planName)
          match = true
        }
        // console.log('match is ' + match + ' flexiplan ' + flexPlanName + ' shareable ' + shareablePlanName + ' plan name ' + planName)
        if (match) {
          if (serviceNumberRow.totalDataAllowance) {
            // console.log('find12MonthsPlanTotal ' + serviceNumberRow.sn + ' adding totalDataAllowance ' + serviceNumberRow.totalDataAllowance + ' for ' + planName)
            totalPlanCapacity += serviceNumberRow.totalDataAllowance
          }
          if (serviceNumberRow.bonusDataAllowance) {
            // console.log('find12MonthsPlanTotal ' + serviceNumberRow.sn + ' adding bonusDataAllowance ' + serviceNumberRow.bonusDataAllowance + ' for ' + planName)
            totalPlanCapacity += serviceNumberRow.bonusDataAllowance
          }
          // console.log('find12MonthsPlanTotal matches ', serviceNumberRow)
          dataHeaders.forEach(yearMonthItem => {
            const yearMonth = yearMonthItem.yearMonth
            // console.log('find12MonthsTotal iterating sn row ' + serviceNumberRow.sn + ' for year month ' + yearMonth)
            if (serviceNumberRow[yearMonth] > 0) {
              if (monthlyTotalMap.get(yearMonth)) { // check if yearMonth exists in map
                // console.log('find12MonthsTotal add to existing ' + yearMonth + ' value ' + serviceNumberRow[yearMonth])
                monthlyTotalMap.set(yearMonth, monthlyTotalMap.get(yearMonth) + serviceNumberRow[yearMonth])
              } else {
                // console.log('find12MonthsTotal put ' + yearMonth + ' value ' + serviceNumberRow[yearMonth])
                monthlyTotalMap.set(yearMonth, serviceNumberRow[yearMonth])
              }
            }
          })
        }
      })
    }
    
    //now we have accumulated the total in the map.. assign it back to the list
    for (let yearMonthColumn of dataHeaders) {
      if (monthlyTotalMap.get(yearMonthColumn.yearMonth)) {
        yearMonthColumn.total = monthlyTotalMap.get(yearMonthColumn.yearMonth)
      }
    }
    console.log('find12MonthsPlanTotal completed for ' + category + ' dataType ' + dataType + ' plan ' + planName)
    return { totalPlanCapacity: totalPlanCapacity, totalUsage: dataHeaders }
  })
}

/**
 * return {yearMonth: yyyyMM, billTotal: 999.99}
 * @param yearMonth
 * @returns {Promise<number>}
 */
async function findBillTotal (yearMonth) {
  console.log('findBillTotal started ')
  return findMonthlyDataTable('BILL', 'TOTAL').then(result => {
    let totalSum = 0.00
    let avg3Months = 0.0
    result.forEach((row) => {
      if (row.doc[yearMonth]) {
        // console.log('adding ' + row.doc[yearMonth] + ' to ' + totalSum)
        avg3Months += row.doc.avg3Months
        totalSum += row.doc[yearMonth]
      }
    })
    console.log('findBillTotal ended totalSum for ' + yearMonth + ' is ' + totalSum)
    return { yearMonth: yearMonth, billTotal: totalSum, avg3Months: avg3Months }
  })
}

async function findSmsTotal (yearMonth) {
  console.log('findSmsTotal started ')
  return findMonthlyDataTable('DOMESTIC SMS', null).then(result => {
    let totalSum = 0
    result.forEach((row) => {
      totalSum += row.doc[yearMonth]
    })
    console.log('findSmsTotal ended totalSum ' + ' for ' + yearMonth + ' is ' + totalSum)
    return { yearMonth: yearMonth, billTotal: totalSum }
  })
}

/**
 * returns totals usage (MB), excess and count for the given yearMonth for non zero calls...
 * @returns {Promise<number|*|u>}
 */
async function findDataUsage (yearMonth, category) {
  console.log('findDataUsage for ' + yearMonth + ' ' + category + ' started')
  let usagePromise = findMonthlyDataTable(category, 'USAGE').then(result => { // usage is MB
    let totalSum = 0
    let usageCount = 0
    let avg3Months = 0.0
    result.forEach((row) => {
      if (row.doc[yearMonth] > 0) {
        if (category === 'OVERSEAS DATA') {
          const previousMonth1 = moment(yearMonth + '01', 'YYYYMMDD').subtract(1, 'months').format('YYYYMM')
          const previousMonth2 = moment(yearMonth + '01', 'YYYYMMDD').subtract(2, 'months').format('YYYYMM')
          const avg3MonthsCalc = (row.doc[yearMonth] + row.doc[previousMonth1] + row.doc[previousMonth2]) / 3
          avg3Months += Math.floor(avg3MonthsCalc) // not sure if we need only do this when year month > 0
        } else {
          if (row.doc.avg3Months) { // if avg3Months exists
            avg3Months += row.doc.avg3Months
          }
        }
        totalSum += row.doc[yearMonth]
        usageCount++
      }
    })
    console.log('findDataUsage ' + category + ' usage ended avg3months ' + ' for ' + yearMonth + ' is ' + avg3Months)
    return { usageSum: totalSum, usageCount: usageCount, usageAvg3Months: avg3Months }
  })
  let excessPromise = findMonthlyDataTable(category, 'EXCESS').then(result => {
    let totalSum = 0
    result.forEach((row) => {
      if (row.doc[yearMonth] > 0) {
        totalSum += row.doc[yearMonth]
      }
    })
    console.log('findDataUsage ' + category + ' excess ended totalSum ' + ' for ' + yearMonth + ' is ' + totalSum)
    return { excessSum: totalSum }
  })
  return Promise.all([usagePromise, excessPromise]).then(([usageP, excessP]) => {
    return {
      usageSum: usageP.usageSum,
      usageCount: usageP.usageCount,
      usageAvg3Months: usageP.usageAvg3Months,
      excessSum: excessP.excessSum,
    }
  })
}

/**
 * for 12 months of national call for sparkline - domestic voice call count + domestic sms count
 * @param startYearMonth
 * @param endYearMonth
 * @returns {Promise<*[]>}
 */
async function find12MonthsNationalCallSparkLine (startYearMonth, endYearMonth) {
  console.log('find12MonthsNationalCallSparkLine started for ' + startYearMonth + ' to ' + endYearMonth)
  return createMonthRange(startYearMonth, endYearMonth).then((result) => {
    const monthList = result // array of ['201801, '201802'...]
    let smsCountPromise = find12MonthsTotal('DOMESTIC SMS', null, monthList).then(result => {
      console.log('find12MonthsNationalCallSparkLine SMS completed ' + startYearMonth + ' ending ' + endYearMonth)
      // console.log(result)
      return { smsList: result }
    })
    let domesticVoiceCallPromise = find12MonthsTotal('DOMESTIC VOICE', 'CALL', monthList).then(result => {
      console.log('find12MonthsNationalCallSparkLine DOMESTIC VOICE CALL completed ' + startYearMonth + ' ending ' + endYearMonth)
      return { callList: result }
    })
    // next line here...
    return Promise.all([smsCountPromise, domesticVoiceCallPromise]).then(([smsCountList, domesticVoiceCountList]) => {
      console.log('find12MonthsNationalCallSparkLine done now combining call count and duration')
      let monthListMap = new Map()
      smsCountList.smsList.forEach(yearMonthItem => {
        if (monthListMap.get(yearMonthItem.yearMonth)) {
          let mapItem = monthListMap.get(yearMonthItem.yearMonth)
          mapItem.call = mapItem.call + yearMonthItem.total
          monthListMap.set(yearMonthItem.yearMonth, mapItem)
        } else {
          monthListMap.set(yearMonthItem.yearMonth, {
            yearMonth: yearMonthItem.yearMonth,
            call: yearMonthItem.total,
          })
        }
      })
      domesticVoiceCountList.callList.forEach(yearMonthItem => {
        if (monthListMap.get(yearMonthItem.yearMonth)) {
          let mapItem = monthListMap.get(yearMonthItem.yearMonth)
          mapItem.call = mapItem.call + yearMonthItem.total
          monthListMap.set(yearMonthItem.yearMonth, mapItem)
        } else {
          monthListMap.set(yearMonthItem.yearMonth, {
            yearMonth: yearMonthItem.yearMonth,
            call: yearMonthItem.total,
            // duration: yearMonthItem.total,
          })
        }
      })
      const monthTotalList = [...monthListMap.values()]
      // console.log(monthTotalList)
      return { callCountList: monthTotalList }
    })
  })
}

/**
 * for 12 months of national data for sparkline - domestic data usage
 * @param startYearMonth
 * @param endYearMonth
 * @returns {Promise<*[]>}
 */
async function find12MonthsNationalDataSparkLine (startYearMonth, endYearMonth) {
  // group the billtotal by months and get the total...
  console.log('find12MonthsNationalDataSparkLine started for ' + startYearMonth + ' to ' + endYearMonth)

  return createMonthRange(startYearMonth, endYearMonth).then((result) => {
    const monthList = result // array of ['201801, '201802'...]
    let usagePromise = find12MonthsTotal('DOMESTIC DATA', 'USAGE', monthList).then(result => {
      console.log('find12MonthsNationalDataSparkLine USAGE completed ' + startYearMonth + ' ending ' + endYearMonth)
      return { usageList: result }
    })
    return Promise.all([usagePromise]).then(([usageList]) => {
      console.log('find12MonthsNationalDataSparkLine done now combining call count and duration')
      let monthListMap = new Map()
      usageList.usageList.forEach(yearMonthItem => {
        if (monthListMap.get(yearMonthItem.yearMonth)) {
          let mapItem = monthListMap.get(yearMonthItem.yearMonth)
          mapItem.usage = mapItem.usage + yearMonthItem.total
          monthListMap.set(yearMonthItem.yearMonth, mapItem)
        } else {
          monthListMap.set(yearMonthItem.yearMonth, {
            yearMonth: yearMonthItem.yearMonth,
            usage: yearMonthItem.total,
            excess: 0,
          })
        }
      })
      let monthTotalList = [...monthListMap.values()]
      // console.log(monthTotalList)
      return { callCountList: monthTotalList }
    })
  })
}

/**
 * for 12 months of international data for sparkline - overseas data usage
 * @param startYearMonth
 * @param endYearMonth
 * @returns {Promise<*[]>}
 */
async function find12MonthsInternationalDataSparkLine (startYearMonth, endYearMonth) {
  // group the billtotal by months and get the total...
  console.log('find12MonthsInternationalDataSparkLine started for ' + startYearMonth + ' to ' + endYearMonth)

  return createMonthRange(startYearMonth, endYearMonth).then((result) => {
    const monthList = result // array of ['201801, '201802'...]
    let usagePromise = find12MonthsTotal('OVERSEAS DATA', 'USAGE', monthList).then(result => {
      console.log('find12MonthsInternationalDataSparkLine USAGE completed ' + startYearMonth + ' ending ' + endYearMonth)
      return { usageList: result }
    })
    return Promise.all([usagePromise]).then(([usageList]) => {
      console.log('find12MonthsInternationalDataSparkLine done now combining call count and duration')
      let monthListMap = new Map()
      usageList.usageList.forEach(yearMonthItem => {
        if (monthListMap.get(yearMonthItem.yearMonth)) {
          let mapItem = monthListMap.get(yearMonthItem.yearMonth)
          mapItem.usage = mapItem.usage + yearMonthItem.total
          monthListMap.set(yearMonthItem.yearMonth, mapItem)
        } else {
          monthListMap.set(yearMonthItem.yearMonth, {
            yearMonth: yearMonthItem.yearMonth,
            usage: yearMonthItem.total,
            excess: 0,
          })
        }
      })
      let monthTotalList = [...monthListMap.values()]
      // console.log(monthTotalList)
      return { callCountList: monthTotalList }
    })
  })
}

async function find12MonthsDomesticSmsTotal (startYearMonth, endYearMonth) {
  console.log('find12MonthsDomesticSmsTotal started for ' + startYearMonth + ' to ' + endYearMonth)
  return createMonthRange(startYearMonth, endYearMonth).then((result) => {
    const monthList = result // array of ['201801, '201802'...]
    let smsCountPromise = find12MonthsTotal('DOMESTIC SMS', null, monthList).then(result => {
      console.log('find12MonthsDomesticSmsTotal CALL completed ' + startYearMonth + ' ending ' + endYearMonth)
      // console.log(result)
      return { smsList: result }
    })

    // next line here...
    return Promise.all([smsCountPromise]).then(([smsCountList]) => {
      console.log('find12MonthsDomesticSms done now combining call count and duration')
      let monthListMap = new Map()
      smsCountList.smsList.forEach(yearMonthItem => {
        if (monthListMap.get(yearMonthItem.yearMonth)) {
          let mapItem = monthListMap.get(yearMonthItem.yearMonth)
          mapItem.call = mapItem.call + yearMonthItem.total
          monthListMap.set(yearMonthItem.yearMonth, mapItem)
        } else {
          monthListMap.set(yearMonthItem.yearMonth, {
            yearMonth: yearMonthItem.yearMonth,
            call: yearMonthItem.total,
            duration: 0,
          })
        }
      })
      const monthTotalList = [...monthListMap.values()]
      // console.log(monthTotalList)
      return { callCountList: monthTotalList }
    })
  })
}

async function find12MonthsDomesticVoiceTotal (startYearMonth, endYearMonth) {
  // group the billtotal by months and get the total...
  console.log('find12MonthsDomesticVoiceTotal started for ' + startYearMonth + ' to ' + endYearMonth)

  return createMonthRange(startYearMonth, endYearMonth).then((result) => {
    const monthList = result // array of ['201801, '201802'...]
    let callCountPromise = find12MonthsTotal('DOMESTIC VOICE', 'CALL', monthList).then(result => {
      console.log('find12MonthsDomesticVoiceTotal CALL completed ' + startYearMonth + ' ending ' + endYearMonth)
      // console.log(result)
      return { callList: result }
    })
    let durationPromise = find12MonthsTotal('DOMESTIC VOICE', 'DURATION', monthList).then(result => {
      console.log('find12MonthsDomesticVoiceTotal DURATION completed ' + startYearMonth + ' ending ' + endYearMonth)
      // console.log(result)
      return { durationList: result }
    })
    return Promise.all([callCountPromise, durationPromise]).then(([callCountList, durationCountList]) => {
      console.log('find12MonthsDomesticVoice done now combining call count and duration')
      let monthListMap = new Map()
      callCountList.callList.forEach(yearMonthItem => {
        if (monthListMap.get(yearMonthItem.yearMonth)) {
          let mapItem = monthListMap.get(yearMonthItem.yearMonth)
          mapItem.call = mapItem.call + yearMonthItem.total
          monthListMap.set(yearMonthItem.yearMonth, mapItem)
        } else {
          monthListMap.set(yearMonthItem.yearMonth, {
            yearMonth: yearMonthItem.yearMonth,
            call: yearMonthItem.total,
            duration: 0,
          })
        }
      })
      durationCountList.durationList.forEach(yearMonthItem => {
        if (monthListMap.get(yearMonthItem.yearMonth)) {
          let mapItem = monthListMap.get(yearMonthItem.yearMonth)
          mapItem.duration = mapItem.duration + yearMonthItem.total
          monthListMap.set(yearMonthItem.yearMonth, mapItem)
        } else {
          monthListMap.set(yearMonthItem.yearMonth, {
            yearMonth: yearMonthItem.yearMonth,
            call: 0,
            duration: yearMonthItem.total,
          })
        }
      })
      let monthTotalList = [...monthListMap.values()]
      // console.log(monthTotalList)
      return { callCountList: monthTotalList }
    })
  })
}

async function find12MonthsDomesticDataUsageAndExcessTotal (startYearMonth, endYearMonth) {
  // group the billtotal by months and get the total...
  console.log('find12MonthsDomesticVoiceTotal started for ' + startYearMonth + ' to ' + endYearMonth)

  return createMonthRange(startYearMonth, endYearMonth).then((result) => {
    const monthList = result // array of ['201801, '201802'...]
    let usagePromise = find12MonthsTotal('DOMESTIC DATA', 'USAGE', monthList).then(result => {
      console.log('find12MonthsDomesticVoiceTotal USAGE completed ' + startYearMonth + ' ending ' + endYearMonth)
      // console.log(result)
      return { usageList: result }
    })
    let excessPromise = find12MonthsTotal('DOMESTIC DATA', 'EXCESS', monthList).then(result => {
      console.log('find12MonthsDomesticVoiceTotal EXCESS completed ' + startYearMonth + ' ending ' + endYearMonth)
      // console.log(result)
      return { excessList: result }
    })
    return Promise.all([usagePromise, excessPromise]).then(([usageList, excessList]) => {
      console.log('find12MonthsDomesticVoice done now combining call count and duration')
      let monthListMap = new Map()
      usageList.usageList.forEach(yearMonthItem => {
        if (monthListMap.get(yearMonthItem.yearMonth)) {
          let mapItem = monthListMap.get(yearMonthItem.yearMonth)
          mapItem.usage = mapItem.usage + yearMonthItem.total
          monthListMap.set(yearMonthItem.yearMonth, mapItem)
        } else {
          monthListMap.set(yearMonthItem.yearMonth, {
            yearMonth: yearMonthItem.yearMonth,
            usage: yearMonthItem.total,
            excess: 0,
          })
        }
      })
      excessList.excessList.forEach(yearMonthItem => {
        if (monthListMap.get(yearMonthItem.yearMonth)) {
          let mapItem = monthListMap.get(yearMonthItem.yearMonth)
          mapItem.excess = mapItem.excess + yearMonthItem.total
          monthListMap.set(yearMonthItem.yearMonth, mapItem)
        } else {
          monthListMap.set(yearMonthItem.yearMonth, {
            yearMonth: yearMonthItem.yearMonth,
            usage: 0,
            excess: yearMonthItem.total,
          })
        }
      })
      let monthTotalList = [...monthListMap.values()]
      // console.log(monthTotalList)
      return { callCountList: monthTotalList }
    })
  })
}

async function findEquipmentBySN (searchSn) {
  console.log('findEquipmentBySN started for SN ' + searchSn)
  return getSnDb()
    .then((snDb) => {
      return snDb.allDocs({
        startkey: searchSn,
        endkey: searchSn + '\ufff0',
        include_docs: true,
      })
    }).then((result) => {
      return result.rows
    })
}

async function findAllEquipment () {
  console.log('findAllEquipment started')
  return getSnDb()
    .then((snDb) => {
      return snDb.allDocs({
        include_docs: true,
      })
    }).then((result) => {
      return result.rows
    })
}

async function findEquipmentDataTable (snTableFilter) {
  console.log('findEquipmentDataTable  started')
  console.log(snTableFilter)
  return getSnDb()
    .then((snDb) => {
      return snDb.allDocs({
        include_docs: true,
      })
    }).then((result) => {
      if (snTableFilter.category === 'ALL') {
        return result.rows
      } else {
        let resultList = []
        result.rows.forEach(serviceNumber => {
          if (snTableFilter.planType) {
            if (snTableFilter.planType === 'FLEXI' && snTableFilter.planName === serviceNumber.doc.flexiPlanName) {
              // do nothing
            } 
            else if (snTableFilter.planType === 'SHARABLE' 
                        && (snTableFilter.planName === serviceNumber.doc.shareablePlanName || 
                            snTableFilter.planName === serviceNumber.doc.shareablePlanName2 || 
                            snTableFilter.planName === serviceNumber.doc.shareableBonusPlanName1 ||
                            snTableFilter.planName === serviceNumber.doc.shareableBonusPlanName2 )) {
              // do nothing
            } else if (snTableFilter.planType === 'NON SHARABLE' 
                        && (snTableFilter.planName === serviceNumber.doc.nonShareablePlanName1 || 
                            snTableFilter.planName === serviceNumber.doc.nonShareablePlanName2 || 
                            snTableFilter.planName === serviceNumber.doc.nonShareableBonusPlanName1 ||
                            snTableFilter.planName === serviceNumber.doc.nonShareableBonusPlanName2 )) {
              // do nothing
            }
            else {
              return
            }
          }
          if (snTableFilter.category === 'deviceType') {
            if (serviceNumber.doc.deviceType === snTableFilter.deviceType) {
              resultList.push(serviceNumber)
            }
          }
          if (snTableFilter.category === 'make') {
            if (serviceNumber.doc.deviceType === snTableFilter.deviceType &&
              serviceNumber.doc.make === snTableFilter.make) {
              resultList.push(serviceNumber)
            }
          }
          if (snTableFilter.category === 'deviceName') {
            if (serviceNumber.doc.deviceType === snTableFilter.deviceType &&
              serviceNumber.doc.make === snTableFilter.make &&
              serviceNumber.doc.deviceName === snTableFilter.deviceName
            ) {
              resultList.push(serviceNumber)
            }
          }
        })
        console.log('findEquipmentDataTable  ended for ' + snTableFilter.category + ' returned ' + resultList.length + ' rows')
        return resultList
      }
    })
}

/**
 *  filterOptions to contain { key: null, deviceType: null, make: null, deviceName: null },
 * @param filterOptions
 * @returns {Promise<*>}
 */
async function findEquipmentSummary (filterOptions, equipmentTableFilter) {
  // group the billtotal by months and get the total...
  console.log('findEquipmentSummary started ', filterOptions)
  // console.log('equipmentTableFilter started ', equipmentTableFilter)

  // Object.keys(yourObject).length === 0
  let filterRequired = true
  if (isEmpty(filterOptions)) {
    filterRequired = false
  }
  return getEquipmentSummaryDb().then((equipmentSummaryDb) => {
    return equipmentSummaryDb.allDocs({
      include_docs: true,
    })
  }).then((result) => {
    let resultList = []
    result.rows.forEach(equipmentSummary => {
      if (filterRequired) {
        if (filterOptions.category === 'deviceType') {
          if (equipmentSummary.doc.deviceType === filterOptions.label) {
            resultList.push(equipmentSummary.doc)
          }
        }
        if (filterOptions.category === 'make') {
          if (equipmentSummary.doc.make === filterOptions.label) {
            resultList.push(equipmentSummary.doc)
          }
        }
        if (filterOptions.category === 'deviceName') {
          if (equipmentSummary.doc.deviceName === filterOptions.label) {
            resultList.push(equipmentSummary.doc)
          }
        }
      } else {
        resultList.push(equipmentSummary.doc)
      }
    })
    return { equipmentSummaryList: resultList }
  })
}

/**
 *  filterOptions to contain {category: 'deviceName', label: 'apple'}
 * @param filterOptions
 * @returns {Promise<*>}
 */
async function findEquipmentSummaryOld (filterOptions) {
  // group the billtotal by months and get the total...
  console.log('findEquipmentSummary started ')
  // Object.keys(yourObject).length === 0
  let filterRequired = false
  if (filterOptions) {
    filterRequired = true
  }
  return getEquipmentSummaryDb().then((equipmentSummaryDb) => {
    return equipmentSummaryDb.allDocs({
      include_docs: true,
    })
  }).then((result) => {
    let resultList = []
    result.rows.forEach(equipmentSummary => {
      if (filterRequired) {
        if (filterOptions.category === 'deviceType') {
          if (equipmentSummary.doc.deviceType === filterOptions.label) {
            resultList.push(equipmentSummary.doc)
          }
        }
        if (filterOptions.category === 'make') {
          if (equipmentSummary.doc.make === filterOptions.label) {
            resultList.push(equipmentSummary.doc)
          }
        }
        if (filterOptions.category === 'deviceName') {
          if (equipmentSummary.doc.deviceName === filterOptions.label) {
            resultList.push(equipmentSummary.doc)
          }
        }
      } else {
        resultList.push(equipmentSummary.doc)
      }
    })
    return { equipmentSummaryList: resultList }
  })
}

/**
 *
 * @param startYearMonth
 * @param endYearMonth
 * @returns {Promise<[]>} list of yearMonth eg ['201801', '201802', '201803']
 */
async function createMonthRange (startYearMonth, endYearMonth) {
  let startOfWeek = moment(startYearMonth + '01', 'YYYYMMDD')
  let endOfWeek = moment(endYearMonth + '01', 'YYYYMMDD')

  let days = []
  let day = startOfWeek

  while (day <= endOfWeek) {
    // days.push(day.toDate());
    days.push(day.format('YYYYMM'))
    day = day.clone().add(1, 'months') // add 1 month
  }
  return days
}

export {
  prepareDashboard, prepareTopTenTable,
  queryLatestMonth,
  createMonthRange,
  find12MonthsTotal,
  findLast12MonthsBillTotal,
  findLast12MonthsDomesticVoiceTotal,
  findLast12MonthsDomesticVoiceSn,
  findLast12MonthsDomesticDataTotal,
  findLast12MonthsDomesticDataPlanUsageTotal,
  findDomesticVoiceTotalForMonthSn,
  find12MonthsOverseasDataTotalCall,
  find12MonthsSMSTotalCall, findMonthlyTopTen, findMonthlyDataTable,
  findMonthlyDataByServiceNumber, findTopTenEquipment, filterTopTenEquipmentChart,
  findEquipmentDataTable,
  findEquipmentBySN,
  findAllEquipment,
  findEquipmentSummary,
  findAllSn,
  countAllSn,
  findSn,
  getTopTenInfo,
  getDashboardInfo,
}
