
import { getCustomerInfo, applyGlobalFilter, getCurrentGlobalFilter, globalFilterALL } from '@/api/PouchDao'
import store from '@/store'

export default {
  // Here we will create action to populate
  async setProgress ({ commit }, progress) {
    // console.log('set progress to ' + progress)
    commit('setProgress', progress)
  },
  async setFirstTimeUse ({ commit }, value) {
    commit('setDataLoadRequired', value)
  },
  async setLoadedDataFlag ({ commit }, value) {
    commit('setDataLoaded', value)
  },
  async loadData ({ commit }) {
    // console.log('loadData called will setGlobalFilter to ' + globalFilterALL)
    return getCustomerInfo().then((customerInfo) => {
      return getCurrentGlobalFilter().then((globalFilter) => {
        // console.log('load data read global filter')
        // console.log(globalFilter)
        commit('setGlobalFilter', globalFilterALL)
        return customerInfo
      })
    }).then((customerInfo) => {
      // console.log('customerInfo loaded from database')
      store.dispatch('configuredOption/loadHeroGraph')
      commit('setCustomerInfo', customerInfo)
      commit('setRefreshRequired', false)
      commit('setDataLoaded', true)
      return customerInfo
    })
  },
  // this call by Settings.vue for filtering account
  async applyGlobalFilterByAccount ({ commit, dispatch }, account) {
    commit('setProgress', true)
    commit('setApplyingGlobalFilter', true)
    return applyGlobalFilter(account).then(result => {
      // console.log('applyGlobalFilterByAccount to account ' + account)
      commit('setProgress', false)
      commit('setGlobalFilter', account)
      commit('setRefreshRequired', true)
      commit('setApplyingGlobalFilter', false)
      // store.dispatch('dashboard/refreshRequired', true)
      store.dispatch('equipment/refreshRequired', true)
      store.dispatch('dashboard/loadData')
      store.dispatch('dashboard/loadTopTen')
      store.dispatch('configuredOption/loadHeroGraph')

      return result
    })
  },
  async resetGlobalFilterToAll ({ commit }) {
    // console.log('resetGlobalFilterToAll called ')
    return applyGlobalFilter(globalFilterALL).then(result => {
      // console.log('resetGlobalFilterToAll completed account is now ', result)
      commit('setGlobalFilter', globalFilterALL)
    })
  },
  async refreshRequired ({ commit }, value) {
    commit('setRefreshRequired', value)
  },
  setAppDrawer ({ commit }, value) {
    commit('setDrawer', value)
  },
  setBarImage ({ commit }, value) {
    commit('setBarImage', value)
  },
  setScrim ({ commit }, value) {
    commit('setScrim', value)
  },
}
